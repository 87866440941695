import { createContext } from 'react';

import BackgroundHome from 'assets/img/home/BG.jpg';
import BackgroundHomeWebp from 'assets/imgWebp/BG.webp';

export const Background = {
  home: {webp: BackgroundHomeWebp, src: BackgroundHome},
};

export const RouterContext = createContext({
  background: '', // default value
  setBackground: () => {},
});
